@import '~antd/dist/antd.css';

.ant-switch {
  background-image: none;
  background-color: rgba(0, 0, 0, .25);
}

.ant-switch-checked {
  background-color: #1890ff;
}

.sa-dashboard-view {
  text-align: center;
  background-color: #282c34;
  min-height: calc(100vh - 64px);
  font-size: calc(10px + 2vmin);
  color: white;
}

.sa-dashboard-cards {
  display: flex;
  flex-wrap: wrap;
}

.sa-dashboard-card {
  width: 216px;
  margin: 10px;
}

.sa-dashboard-charts {
  display: flex;
  flex-wrap: wrap;
}

.sa-dashboard-chart {
  margin: 10px;
}

.sa-auction-view {
  text-align: center;
  background-color: #282c34;
  min-height: calc(100vh - 134px);
  font-size: calc(10px + 2vmin);
  color: white;
}

.sa-cards {
  display: flex;
  flex-wrap: wrap;
}

.sa-auction-card-container {
  width: 216px;
  margin: 10px;
}

.sa-auction-card-container .ant-card-body {
  background-color: #FEFEFE;
}

.sa-auction-card-container .ant-card-cover {
  background-color: #282c34;
}

.sa-auction-card-container .ant-card {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.sa-auction-card-container .ant-card-body {
  padding: 4px 15px 4px 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.sa-auction-card-bottom {
  min-height: 170px;
}

.sa-card-bottom-no-scores {
  min-height: 55px;
}

.sa-auction-time-badges-block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sa-u23-badge {
  padding-left: 4px;
  font-weight: bold;
}

.sa-1st-badge {
  padding-left: 4px;
  font-weight: bold;
  color: darkviolet;
}

.sa-shirt-badge {
  padding-left: 4px;
  font-weight: bold;
  color: darkviolet;
}

.sa-on-sale-badge {
  padding-right: 8px;
  font-weight: bold;
  color: red;
}

.sa-card-bonus {
  font-size: 12px;
  font-weight: bold;
  font-family: "Roboto", "Helvetica", "Arial";
  padding-right: 8px;
}

.sa-refresh-button {
  padding-right: 8px;
}

.sa-card-bonus-breakdown {
  font-size: 12px;
  color: black;
}

.sa-card-bonus-breakdown-row {
  display: flex;
  justify-content: space-between;
}

.sa-card-bonus-breakdown-value {
  font-weight: bold;
  color: #23C3A3;
  padding-left: 8px;
}

.sa-auction-prices-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0px 4px 0px;
}

.sa-player-auction-card-prices-block {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0px 4px 0px;
}

.sa-auction-price {
  font-size: 16px;
  font-weight: bold;
}

.sa-auction-next-price {
  font-size: 12px;
}

.sa-auction-market-price {
  font-size: 12px;
}

.sa-player-profile-buttons {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.sa-player-profile-button-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sa-player-profile {
  display: flex;
  justify-content: center;
}

.sa-player-profile-card {
  margin: 10px;
  min-height: 498px;
  max-width: 500px;
}

.sa-player-profile-card .ant-card-body {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 486px;
}

.sa-prices-card {
  margin: 10px;
  min-height: 498px;
}

.sa-prices-card .ant-card-body {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 486px;
}

.sa-price-row-block {
  padding-top: 10px;
}

.sa-player-search-row {
  display: flex;
  align-items: center;
}

.sa-player-search-name {
  font-size: 16px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
}

.sa-player-search-club {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.75);
}

.sa-player-search-position-age {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.55);
}

.sa-player-profile-name {
  font-size: 26px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
}

.sa-player-profile-club {
  font-size: 22px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.75);
}

.sa-player-profile-league {
  font-size: 22px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
}

.sa-player-profile-position-age {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.55);
}

.sa-player-profile-image {
  margin-top: 20px;
  width: 300px;
  height: 285px;
  display: inline-block;
  background-size: cover;
}

.sa-player-profile-rarity-select {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.sa-scores-blocks {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.sa-scores-block {
  margin: 10px;
  width: 260px;
}

.sa-scores-card-title {
  font-size: 16px;
}

.sa-scores-card-additional-title {
  font-size: 14px;
  color: grey;
}

.sa-scores-card {
  margin-bottom: 10px;
}

.sa-scores-card-bottom {
  min-height: 110px;
}

.sa-scores-table {
  margin: 24px;
}

.sa-auction-filters {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sa-scores-modal-score-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sa-prices-modal-prices-inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sa-emoji-color-red {
  filter: grayscale(100%) url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg'><filter id='f'><feColorMatrix color-interpolation-filters='sRGB' type='matrix' values='1 0 0 0 0  0 0.4 0 0 0  0 0 0 0.4 0  0 0 0 1 0'/></filter></svg>#f");
}

.sa-emoji-color-green {
  filter: grayscale(100%) url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg'><filter id='f'><feColorMatrix color-interpolation-filters='sRGB' type='matrix' values='0 0 0 0 0  0 1 0 0 0  0 0 0 0 0  0 0 0 1 0'/></filter></svg>#f");
}

.sa-decisive-scores-icons {
  display: flex;
  justify-content: space-evenly;
  font-size: 18px;
}

.sa-gw-tiles {
  display: flex;
  flex-wrap: wrap;
}

.sa-gw-tile {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.sa-gw-dnp-text {
  color: rgba(255, 0, 0, 0.75);
  font-weight: bold;
}

.sa-score-row {
  display: flex;
  align-items: center;
}

.sa-nba-diff-row {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}

.sa-lineup-stats {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 16px;
}